import { lazy } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { FormType } from './core/constants/enum';
import { Test } from '@components/Test';

const Home = lazy(() => import('@pages/Home'));
// ** Components
const Layout = lazy(() => import('@components/Layout'));

const MyPage = lazy(() => import('@pages/MyPage'));
const MyPageMain = lazy(() => import('@src/pages/MyPage/MyPageMain'));
const MyInfo = lazy(() => import('@pages/MyPage/MyInfo'));
const Point = lazy(() => import('@pages/MyPage/Point'));
const Accounts = lazy(() => import('@pages/MyPage/Accounts'));
const AccountsList = lazy(() => import('@pages/MyPage/Accounts/List'));
const AccountAddEditForm = lazy(() => import('@src/components/Forms/AccountForm'));

const Patient = lazy(() => import('@pages/Patient'));
const PatientList = lazy(() => import('@pages/Patient/List'));
const PatientDetail = lazy(() => import('@pages/Patient/Detail'));
const PatientDetailTestHistory = lazy(() => import('@pages/Patient/Detail/TestHistory'));
const PatientAddEditForm = lazy(() => import('@src/components/Forms/PatientForm'));
const PatientTest = lazy(() => import('@pages/Patient/Detail/Test'));

const PatientTestDetailSummary = lazy(
  () => import('@src/pages/Patient/Detail/TestHistory/Summary'),
);

const Btt = lazy(() => import('@src/pages/Evaluation/Btt/Btt'));
const route: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: '*',
        element: <Layout />,
        children: [
          { index: true, element: <Navigate to="/evaluation" replace /> },
          {
            path: 'my-page',
            element: <MyPage />,
            children: [
              { index: true, element: <MyPageMain /> },
              {
                path: 'info',
                element: <MyInfo />,
              },
              { path: 'point', element: <Point /> },
              {
                path: 'accounts',
                element: <Accounts />,
                children: [
                  { index: true, element: <AccountsList /> },
                  {
                    element: <AccountsList />,
                    children: [
                      {
                        path: 'add',
                        element: <AccountAddEditForm />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'evaluation',
            children: [
              { index: true, element: <Navigate to="nsap" replace /> },
              {
                path: 'nsap',
                element: <Patient />,
                children: [
                  { index: true, element: <PatientList /> },
                  {
                    element: <PatientList />,
                    children: [
                      {
                        path: 'add',
                        element: <PatientAddEditForm type={FormType.ADD} />,
                      },
                    ],
                  },
                  {
                    path: ':patientId',
                    element: <PatientDetail />,
                    children: [
                      { index: true, element: <PatientDetailTestHistory /> },
                      {
                        element: <PatientDetailTestHistory />,
                        children: [
                          {
                            path: 'edit',
                            element: <PatientAddEditForm type={FormType.EDIT} />,
                          },
                          {
                            path: 'summary/:sessionId',
                            element: <PatientTestDetailSummary />,
                          },
                          {
                            path: 'summary/:sessionId',
                            element: <PatientTestDetailSummary />,
                          },
                        ],
                      },

                      {
                        path: 'test',
                        element: <PatientTest />,
                        children: [
                          {
                            path: 'add/:sessionId/:testRecordId/:firstCategoryCode',
                            element: <Test />,
                          },
                          {
                            path: ':sessionId/:testRecordId/:firstCategoryCode',
                            element: <Test isDetail />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'btt',
                element: <Btt />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Navigate to="/home" replace />,
  },
  {
    path: '/find-id',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/restore-password',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/logout',
    element: 'logout',
  },
];

export default route;
