import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import cookieStorage from '@core/lib/cookie-storage';

export const defaultValue = {
  user: null,
  access: null,
  refresh: null,
  role: '',
};

const { persistAtom } = recoilPersist({
  key: 'auth',
  storage: cookieStorage,
});

export const auth = atom({
  key: 'auth',
  default: defaultValue,
  effects: [persistAtom],
});
