import { Stack } from '@mui/material';
import { usePatient } from '@src/modules/patient';
import dayjs from 'dayjs';

export const PatientInfo = ({ patientId }: { patientId: string }) => {
  const { data: patient } = usePatient(Number(patientId));

  return (
    <Stack
      direction="row"
      gap={3}
      sx={(theme) => ({
        color: theme.color.grey[900],
        fontSize: '1rem',
        fontWeight: 400,
        mt: '-22px',
        mb: '40px',
      })}
    >
      <div>{`${patient?.name} (${patient?.sex})`}</div>
      <div>{`${(patient?.dateOfBirth ?? '')?.replaceAll('-', '.')} (${
        dayjs().year() - dayjs(patient?.dateOfBirth).year()
      }세)`}</div>
    </Stack>
  );
};
